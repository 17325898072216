.whiteboard-wrapper {
    display: flex;
    flex-flow: column nowrap;
    height: 100vh;
    width: calc(100vw - 30px);
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    position: relative;

    h1 {
        color: #fff;
    }

    .whiteboard-frame {
        width: 100%;
        height: calc(100vh - 200px);
        border: 1px solid rgba(150,150,150,0.7);
        border-radius: 5px;
        box-shadow: 3px 3px 4px rgba(50,50,50,0.4);
        padding: 20px;
        box-sizing: border-box;
        background: linear-gradient(50deg, rgba(239, 239, 239, 1), rgba(254, 254, 254,1));

        .inner-space {
            box-sizing: border-box;
            position: relative;
            width: 100%;
            height: 100%;
            background: #fff;
            border: inset 1px rgba(50,50,50,0.2);
            box-shadow: inset 1px 1px 2px rgba(100,100,100,0.2);
            
            .board-overlay {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: rgba(0,0,0,0.15);
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

    }
}

.whiteboard-fab {
    margin: 0;
    top: auto;
    right: 40px;
    bottom: 105px;
    left: auto;
    position: absolute !important;
    z-index: 5;
}
