.tab-item {
    display: flex;
    justify-content: space-between;
    /* border: 1px solid #ddd; */
    padding: 10px 20px;
    cursor: pointer;
    min-width: max-content;
}

.tab-item.active {
    background: rgba(255,255,255,1);
    box-shadow: 0px 2px 4px rgba(0,0,0,0.2);
}

.tab-item .icon {
    border-radius: 50%;
    font-size: 15px;
    margin: auto auto auto 5px;
}