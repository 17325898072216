.main {
    display: flex;
    flex-flow: column nowrap;
    background-attachment: fixed !important;
    min-height: 100%;
    height: initial;
}

.loading-overlay {
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    background-image: radial-gradient(farthest-corner at -0% 100%,#0066ff 30%,#10aada 95%);
}