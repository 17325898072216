body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, html, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  background-image: radial-gradient(farthest-corner at -0% 100%,#0066ff 30%,#10aada 95%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main {
  display: flex;
  flex-flow: column nowrap;
  background-attachment: fixed !important;
  min-height: 100%;
  height: initial;
}

.clickable {
  cursor: pointer;
}

.clickable:hover {
  background: rgba(0,0,0,0.2);
}