.tab-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background: rgba(240,240,240,1);
    border-top: 1px solid #3f51b5;
    z-index: 2;
    display: flex;
    justify-content: flex-start;
    overflow-x: auto;
}